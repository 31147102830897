module.exports = {
  tHome: 'Crossdressing Blog',
  tAbout: 'O mnie',
  tCategories: 'Kategorie',
  tTutorials: 'Poradniki',
  tClothes: 'Ubrania',
  tShoes: 'Buty',
  tMakeup: 'Makijaż',
  tLinks: 'Polecane linki',
  tLifestyle: 'Styl życia',
  tMyStory: 'Moja historia',
  tRelativePosts: 'Polecane posty',
  tFollowTwitterDescription: 'Obserwuj na Twitterze',
  tTags: 'Tagi',
  tIndTitle: 'Crossdressing Blog Polska',
  taIndKeywords: [`blog`, `crossdressing`, `crossdresser`, `transwestyta`],
  tfIndCountPosts: count => `${count} Posty`,
  tfTagHeader: (totalCount, tag) =>
    `${totalCount} post${totalCount === 1 ? '' : 's'} otagowane z "${tag}"`,
  t404Title: 'Nie znaleziono',
  t404Content: 'Podany adres nie istnieje.',
  bio: 'Lubię siebie nazywać osobą genderfluid. Urodziłem się jako mężczyzna i przez długi czas funkcjonowałem w takiej formie. Ale od jakiegoś czasu moja kobieca natura daje mocno o sobie znać. Tworzę szczęśliwy związek z kobietą mojego życia, która mnie wspiera z całego serca. Na tym blogu chciałbym/chciałabym przedstawić, jak wygląda życie z mojej perspektywy, crossdressera, osoby transpłciowej czy dawniej transwestyty. Dodatkowo chcę również pomóc osobom zagubionym w tym świecie poprzez poradniki, jak i bezpośrednią rozmowę. Piszcie pytania w komentarzach na blogu lub bezpośrednio do mnie na maila/Instagrama/Facebooka. W miarę możliwości postaram się pomóc.',
  tSortByPopularity: 'Sortuj posty według popularności',
  tSortByDate: 'Sortuj posty według daty',
  tPrevious: 'Poprzednia strona',
  tNext: 'Następna strona',
};
